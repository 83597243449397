@font-face {
  font-family: Raleway-Black;
  src: url("public/font/Raleway-Black.ttf");
}
@font-face {
  font-family: Raleway-BlackItalic;
  src: url("public/font/Raleway-BlackItalic.ttf");
}
@font-face {
  font-family: Raleway-Bold;
  src: url("public/font/Raleway-Bold.ttf");
}
@font-face {
  font-family: Raleway-BoldItalic;
  src: url("public/font/Raleway-BoldItalic.ttf");
}
@font-face {
  font-family: Raleway-ExtraBold;
  src: url("public/font/Raleway-ExtraBold.ttf");
}
@font-face {
  font-family: Raleway-ExtraBoldItalic;
  src: url("public/font/Raleway-ExtraBoldItalic.ttf");
}
@font-face {
  font-family: Raleway-ExtraLight;
  src: url("public/font/Raleway-ExtraLight.ttf");
}
@font-face {
  font-family: Raleway-ExtraLightItalic;
  src: url("public/font/Raleway-ExtraLightItalic.ttf");
}
@font-face {
  font-family: Raleway-Italic;
  src: url("public/font/Raleway-Italic.ttf");
}
@font-face {
  font-family: Raleway-Light;
  src: url("public/font/Raleway-Light.ttf");
}
@font-face {
  font-family: Raleway-LightItalic;
  src: url("public/font/Raleway-LightItalic.ttf");
}
@font-face {
  font-family: Raleway-Medium;
  src: url("public/font/Raleway-Medium.ttf");
}
@font-face {
  font-family: Raleway-MediumItalic;
  src: url("public/font/Raleway-MediumItalic.ttf");
}
@font-face {
  font-family: Raleway-Regular;
  src: url("public/font/Raleway-Regular.ttf");
}
@font-face {
  font-family: Raleway-SemiBold;
  src: url("public/font/Raleway-SemiBold.ttf");
}
@font-face {
  font-family: Raleway-SemiBoldItalic;
  src: url("public/font/Raleway-SemiBoldItalic.ttf");
}
@font-face {
  font-family: Raleway-Thin;
  src: url("public/font/Raleway-Thin.ttf");
}
@font-face {
  font-family: Raleway-ThinItalic;
  src: url("public/font/Raleway-ThinItalic.ttf");
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
} /* prevent adjustments of font size after orientation changes in IE and iOS */

.App {
  text-align: center;
}

.App-logo {
  /* height: 40vmin; */
  pointer-events: none;
}

html {
  font-size: 15px;
  scroll-behavior: smooth;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 476px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 768px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }

  .container {
    width: 720px;
    max-width: 100%;
  }

  .hidden-md {
    display: none !important;
  }
  .animation-player {
    height: 70vh;
  }
  .App-header.bg-black a {
    color: #200b47 !important;
  }
}
@media (min-width: 992px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 1200px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 476px) {
  .container {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .container {
    width: 960px;
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1140px;
    max-width: 100%;
  }
}
@media (min-width: 1400px) {
  .container {
    width: 1140px;
    max-width: 100%;
  }
}

.App-header {
  position: fixed;
  top: 2rem;
  background-color: transparent;
  color: #fff;
  min-width: 100%;
  z-index: 1;
}

.App-header.bg-black {
  position: fixed;
  top: 0rem;
  border-bottom: 2px solid #ff5777;
  min-width: 100%;
  z-index: 2;
  padding: 0.5rem 0;
  background: #fff;
  z-index: 16;
}

.App-header.bg-black a {
  color: #200b47;
}

.TopPage {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

ul {
  list-style-type: none;
}

.TopPage__Left {
  background: url("../src/public/img/common/banner-bg-left.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
}

.TopPage__Right {
  background: url("../src/public/img/common/banner-bg-right.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
}

.TopPage .ant-row {
  min-width: 100%;
  min-height: 100vh;
}

/* .App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
.back-to-top {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: none;
  color: #fff;
  background: #fb4242;
  font-size: 20px;
  cursor: pointer;
  padding: 0;
}

.ant-back-top {
  right: 8px !important;
  bottom: 48px !important;
}

.TopPage__Left img {
  position: absolute;
  left: 13vw;
  top: 13vh;
  width: 12vw;
}

.TopPage__Right img {
  position: absolute;
  bottom: 3vh;
  right: 3vw;
  width: 20vw;
}

.TopPage__Left img {
  animation-name: imgLeft;
  animation-duration: 6s;
  animation-iteration-count: infinite;
}

.TopPage__Right img {
  animation-name: imgRight;
  animation-duration: 6s;
  animation-iteration-count: infinite;
}

@keyframes imgLeft {
  0% {
    top: 13vh;
  }
  50% {
    top: 15vh;
  }
  100% {
    top: 13vh;
  }
}

@keyframes imgRight {
  0% {
    bottom: 3vh;
  }
  50% {
    bottom: 5vh;
  }
  100% {
    bottom: 3vh;
  }
}

a.animated-button:link:after,
a.animated-button:visited:after {
  content: "";
  position: absolute;
  height: 0%;
  left: 50%;
  top: 50%;
  width: 150%;
  z-index: -1;
  -webkit-transition: all 0.75s ease 0s;
  -moz-transition: all 0.75s ease 0s;
  -o-transition: all 0.75s ease 0s;
  transition: all 0.75s ease 0s;
}
a.animated-button:link:hover,
a.animated-button:visited:hover {
  color: #c0161f;
  text-shadow: none;
}
a.animated-button:link:hover:after,
a.animated-button:visited:hover:after {
  height: 520%;
}
a.animated-button:link,
a.animated-button:visited {
  position: relative;
  display: block;
  margin: 0px auto 0;
  padding: 10px 30px;
  color: #fff;
  font-family: Raleway-Regular;
  font-weight: 700;
  font-size: 1.6rem;
  border-radius: 40px;
  text-align: center;
  text-decoration: none;
  width: 290px;
  overflow: hidden;
  letter-spacing: 0.08em;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.2), 0 1px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
  z-index: 1;
  background: transparent;
}

a.animated-button.thar-three {
  color: #fff;
  cursor: pointer;
  display: block;
  position: relative;
  border: 2px solid #fff;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}
a.animated-button.thar-three:hover {
  color: #fff !important;
  background-color: transparent;
  text-shadow: nthree;
  border-color: #fb6084;
}
a.animated-button.thar-three:hover:before {
  left: 0%;
  right: auto;
  width: 100%;
}
a.animated-button.thar-three:before {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 0px;
  z-index: -1;
  content: "";
  color: #fff !important;
  background: #fb6084;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}

a.animated-button.thar-pink {
  color: #fff;
  cursor: pointer;
  display: block;
  position: relative;
  border: 2px solid #fff;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
  background: #fb6084;
  display: block;
  position: absolute;
  left: calc(25vw - 3rem);
  bottom: -2rem;
}
a.animated-button.thar-pink:hover {
  color: #fb6084 !important;
  background-color: transparent;
  text-shadow: nthree;
  border-color: #fb6084;
}
a.animated-button.thar-pink:hover:before {
  left: 0%;
  right: auto;
  width: 100%;
}
a.animated-button.thar-pink:before {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 0px;
  z-index: -1;
  content: "";
  color: #fb6084 !important;
  background: #fff;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}

a.animated-button.thar-blue {
  color: #fff;
  cursor: pointer;
  display: block;
  position: relative;
  border: 2px solid #fff;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
  background: #5052ac;
  display: block;
  position: absolute;
  right: calc(25vw - 10rem);
  bottom: -2rem;
}
a.animated-button.thar-blue:hover {
  color: #5052ac !important;
  background-color: transparent;
  text-shadow: nthree;
  border-color: #5052ac;
}
a.animated-button.thar-blue:hover:before {
  left: 0%;
  right: auto;
  width: 100%;
}
a.animated-button.thar-blue:before {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 0px;
  z-index: -1;
  content: "";
  color: #5052ac !important;
  background: #fff;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}

a.animated-button.thar-four {
  color: #fff;
  cursor: pointer;
  display: block;
  position: relative;
  border: 2px solid #fff;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}
a.animated-button.thar-four:hover {
  color: #fff !important;
  background-color: transparent;
  text-shadow: nfour;
  border-color: #5052ac;
}
a.animated-button.thar-four:hover:before {
  right: 0%;
  left: auto;
  width: 100%;
}
a.animated-button.thar-four:before {
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 0px;
  z-index: -1;
  content: "";
  color: #fff !important;
  background: #5052ac;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}

a.animated-button.thar-four:link,
a.animated-button.thar-four:visited {
  background: transparent;
}

.TopPage__boxLeft h1,
.TopPage__boxRight h1 {
  font-size: 4.8rem;
  color: #fff;
  font-family: Raleway-Regular;
  font-weight: 700;
  margin-bottom: 2rem;
  line-height: 4.8rem;
}

.TopPage__boxLeft p,
.TopPage__boxRight p {
  font-size: 1.6rem;
  color: #fff;
  font-family: Raleway-Regular;
  font-weight: 700;
  margin-bottom: 3rem;
}

/* .nav-options a {
  font-family: "Raleway-Bold";
  font-size: 1.2rem;
  color: #fff !important;
  font-weight: 700;
  padding: 2rem;
}

.nav-options a:hover {
  color: #f97b9c;
}

.nav-options {
  grid-template-columns: repeat(3, auto);
  grid-gap: 50px;
  list-style-type: none;
} */
.mobile-option {
  display: none;
}

.option :hover {
  color: white;
}
.signin-up {
  display: flex;
  padding: 0px 5px;
  list-style-type: none;
}
.sign-in {
  padding-right: 50px;
  align-self: center;
}
.sign-in :hover {
  color: white;
}
.signup-btn {
  padding: 10px 10px;
  height: 2.4rem;
  border-radius: 3px;
  background: rgb(222, 9, 241);
  color: white;
  cursor: pointer;
  align-items: center;
}
.mobile-menu {
  cursor: pointer;
  display: none;
  color: #323396;
  font-size: 2rem;
  text-align: right;
  padding-right: 1rem;
}

.mobile-menu .anticon-menu {
  padding: 0.5rem;
  background: #f9f9f9;
}

.PageDescription h1,
.EcardBlock h1 {
  font-size: 2.67rem;
  font-family: Raleway-Bold;
  margin-bottom: 0;
  line-height: 3.4rem;
}

.PageDescription p,
.EcardBlock p {
  font-family: "Raleway-Medium";
  font-size: 1.07rem;
  line-height: 2rem;
  margin-top: 2rem;
}

.color-orange {
  color: #f72e2e;
}

.color-dark {
  color: #3f3b3b;
}

.font-bold {
  font-family: Raleway-Bold;
}

@media (max-width: 767px) {
  a.animated-button.thar-three {
    background: linear-gradient(
      300.82deg,
      rgba(255, 142, 179, 0.79) -12.86%,
      #f95774 77.77%
    ) !important;
  }
  a.animated-button.thar-four {
    background: linear-gradient(180deg, #44449e 0%, #6b72cc 100%) !important;
  }
  /*Mobile View */
  .header {
    padding: 0px 10px;
  }
  .logo {
    width: 45px;
    height: 45px;
  }
  /* .nav-options {
    display: flex;
    width: 100%;
    height: 350px;
    position: absolute;
    top: 63px;
    left: -200%;
    opacity: 0;
    transition: all 0.5s ease;
    flex-direction: column;
    list-style-type: none;
    grid-gap: 0px;
  }
  .nav-options.active {
    background: linear-gradient(180deg, #282da2 0%, #2e1a52 100%);
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 9999;
    align-content: center;
    padding-left: 0px;
    width: 200%;
    height: 100vh;
    padding: 8rem 0;
  } */

  .menu-icon {
    width: 45px;
    height: 45px;
  }
  /* .option {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 10vw;
    padding: 30px 0px;
    flex-direction: column;
  } */
  .sign-up {
    background: rgb(222, 9, 241);
    border-radius: 3px;
    color: white;
    padding: 20px 0px;
    width: 80%;
    align-self: center;
    border-radius: 3px;
  }

  .signin-up {
    display: none;
  }
  /* .mobile-menu {
    display: block;
  } */

  /* .App-header {
    position: relative;
    top: 0;
  } */

  .TopPage .ant-row {
    z-index: 0;
  }

  .p-speeddial {
    z-index: 1;
  }

  .hidden-sm {
    display: none !important;
  }

  .m-b-sm-3rem {
    margin-bottom: 3rem;
  }

  .footer--bottom p {
    text-align: center !important;
  }

  .App-header {
    padding: 1rem 0 !important;
  }

  .App-logo {
    max-width: 8rem;
  }

  .App-header .ant-row > .ant-col {
    text-align: left;
    padding-left: 1rem;
  }

  a.animated-button:link,
  a.animated-button:visited {
    font-size: 1.3rem;
  }

  .p-speeddial {
    right: 0rem !important;
  }

  .ant-back-top {
    right: 8px !important;
    bottom: 1.5rem !important;
  }

  .p-t-sm-1rem {
    padding-top: 1rem;
  }

  .ant-carousel {
    max-height: 75vh;
  }

  div.swiper-scrollbar {
    max-width: 70vw !important;
  }
  .ecard-body > img {
    min-height: 50vh;
    max-height: 50vh;
  }
  .modal--showImg img {
    /* max-height: 75vh; */
    max-width: 80vw;
  }
  .swiper-button-prev,
  .swiper-button-next {
    display: none !important;
  }
}

.product-item {
  padding: 2rem;
}

.product-item__content {
  background: #ffffff;
  border: 2px solid #f8f8f8;
  box-sizing: border-box;
  box-shadow: 12px 12px 27px rgb(225 225 225 / 50%);
  border-radius: 30px;
  padding: 2rem 1.3rem;
}

.product-item__content:hover {
  box-shadow: 12px 12px 27px rgb(249 137 165 / 50%);
}

.m-b-0 {
  margin-bottom: 0;
}

h4.product-item__title {
  font-size: 1.4rem;
  font-family: "Raleway-Bold";
}

p.product-item__desc {
  font-size: 0.93rem;
  font-family: "Raleway-Regular";
  word-break: break-word;
}

.imgPath {
  margin-bottom: 2rem;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  width: 3.4rem;
  height: 0.3rem;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: #ffd4db;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background-color: #f24463;
}

.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffd4db;
}

.p-carousel-prev,
.p-carousel-next {
  display: none;
}

.p-carousel-content {
  flex-direction: column-reverse;
}

@media (max-width: 767px) {
  .show-sm {
    display: initial;
  }
  .PageDescription {
    margin-top: 2rem;
  }

  .EcardBlock {
    margin-top: 0rem;
    padding-top: 2rem;
  }

  .TopPage__Left,
  .TopPage__Right {
    height: 50vh;
  }

  .TopPage .ant-row {
    min-height: 50vh;
  }

  .TopPage__Left img {
    width: 30vw;
    top: 0;
    left: 5vw;
  }
  .TopPage__Right img {
    width: 30vw;
  }
  @keyframes imgLeft {
    0% {
      top: 5vh;
    }
    50% {
      top: 7vh;
    }
    100% {
      top: 5vh;
    }
  }

  .footer--top__container {
    padding: 2rem 0 2rem !important;
  }

  .text-right-sm {
    text-align: right !important;
  }

  .footer--top p {
    flex-direction: column;
  }

  .EcardBlock h1,
  .PageDescription h1 {
    font-size: 2.2rem;
  }

  .TopPage__boxLeft h1,
  .TopPage__boxRight h1 {
    font-size: 2.4rem !important;
    margin-bottom: 0.5rem !important;
  }

  .TopPage__boxLeft p,
  .TopPage__boxRight p {
    font-size: 1.4rem;
  }

  .List-Guide--Top__Right,
  .List-Guide--Bottom__Right {
    padding-right: 2rem;
  }
  .CenteredPage--Top,
  .CenteredPage--Bottom {
    margin-top: 0rem;
    padding-top: 2rem !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .PageDescription {
    margin-top: 3rem;
  }
  .EcardBlock {
    margin-top: 4rem;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .PageDescription {
    margin-top: 4rem;
  }
  .EcardBlock {
    margin-top: 5rem;
  }
}

@media (min-width: 1024px) {
  .p-carousel-indicators {
    display: none;
  }
  .PageDescription {
    padding-top: 5rem;
  }
  .EcardBlock {
    padding-top: 5rem;
  }
}

.List-Guide--Top {
  display: flex;
}

.CenteredPage--Top {
  padding-top: 5rem;
  position: relative;
}

.CenteredPage--Top h1 {
  font-size: 2.67rem;
  font-family: Raleway-Bold;
  margin-bottom: 3rem;
  line-height: 3.4rem;
}

.text-left {
  text-align: left;
}

.List-Guide--Top__Right {
  padding-left: 2rem;
}

.List-Guide--Top__Right h4 {
  font-size: 1.5rem;
  font-family: Raleway-Bold;
  color: #3f3b3b;
  line-height: 1.5rem;
  margin-bottom: 1rem;
}

.List-Guide--Top__Right p {
  font-size: 1rem;
  font-family: "Raleway-Medium";
  margin-bottom: 0.5rem;
  line-height: 1.5rem;
}

.font-italic-medium {
  font-family: "Raleway-MediumItalic";
}

.List-Guide--Top__Right li {
  font-size: 1rem;
  font-family: "Raleway-MediumItalic";
}

.List-Guide--Top__Right ul {
  list-style-type: disc;
  padding-inline-start: 1.5rem;
  margin-bottom: 0.5rem;
  justify-content: space-between;
  display: flex;
}

.List-Guide--Top__Right li {
  width: 45%;
}

.m-b-2rem {
  margin-bottom: 2rem;
}

.m-b-4rem {
  margin-bottom: 4rem;
}

.List-Guide--Top__Right a {
  color: #60a4ff;
}

.List-Guide--Top__Right p,
.List-Guide--Bottom__Right p {
  word-break: break-word;
}

/* .CenteredPage--Top {
  background: url("../src/public/img/common/centerd-page-left.png");
  background-repeat: no-repeat;
  background-size: 50% auto;
  background-position: top 1.5rem left;
} */

.List-Guide--Bottom {
  display: flex;
}

.CenteredPage--Bottom {
  padding-top: 6rem;
  position: relative;
}

.CenteredPage--Bottom h1 {
  font-size: 2.67rem;
  font-family: Raleway-Bold;
  margin-bottom: 5rem;
  line-height: 3.4rem;
}

.List-Guide--Bottom__Right {
  padding-left: 2rem;
}

.List-Guide--Bottom__Right h4 {
  font-size: 1.5rem;
  font-family: Raleway-Bold;
  color: #3f3b3b;
  line-height: 1.5rem;
  margin-bottom: 1rem;
}

.List-Guide--Bottom__Right p {
  font-size: 1rem;
  font-family: "Raleway-Medium";
  margin-bottom: 0.5rem;
  line-height: 2rem;
}

.List-Guide--Bottom__Right li {
  font-size: 1rem;
  font-family: "Raleway-MediumItalic";
}

.List-Guide--Bottom__Right ul {
  list-style-type: disc;
  padding-inline-start: 1.5rem;
  margin-bottom: 0.5rem;
}

.List-Guide--Bottom__Right a {
  color: #60a4ff;
}

/* .CenteredPage--Bottom {
  background: url("../src/public/img/common/centerd-page-right.png");
  background-repeat: no-repeat;
  background-size: 45% auto;
  background-position: top right;
  margin-top: 6rem;
} */

.slick-active {
  word-break: break-all;
}

.ant-carousel .slick-dots li.slick-active button {
  background: #fb4242 !important;
  opacity: 1;
}

.ant-carousel .slick-dots li button {
  background: #fb4242 !important;
  opacity: 0.3;
}

.ant-carousel .slick-slide img {
  display: initial !important;
}

/* .CenteredPage--Top__Left {
  height: 100%;
}

.CenteredPage--Top__Left img {
  width: 100%;
} */

/* .CenteredPage--Bottom__Right {
  height: 100%;
}

.CenteredPage--Bottom__Right img {
  width: 100%;
} */

.p-l-3rem {
  padding-left: 3rem;
}

.CenteredPage--Top__Left-container .title--sm,
.CenteredPage--Bottom__Left-container .title--sm {
  position: absolute;
  width: 100%;
  top: 6rem;
}

.CenteredPage--Top__Left-container .title--sm h4,
.CenteredPage--Bottom__Left-container .title--sm h4 {
  color: #fff;
  font-size: 1.87rem;
  line-height: 1.87rem;
  font-family: "Raleway-Bold";
}

.CenteredPage--Top__Left-container .hidden-md .hidden-md__bg,
.CenteredPage--Bottom__Left-container .hidden-md .hidden-md__bg {
  width: 100%;
}

.CenteredPage--Top__Left-container .hidden-md .slick-track,
.CenteredPage--Bottom__Left-container .hidden-md .slick-track {
  margin-top: 3rem;
}

.CenteredPage--Top__Left-container .hidden-md .List-Guide--Top__Right ul {
  flex-direction: column;
}

.CenteredPage--Top__Left-container .hidden-md .List-Guide--Top__Right li {
  width: 100%;
}

.CenteredPage--Top__Left-container .hidden-md .List-Guide--Top__Left,
.CenteredPage--Bottom__Left-container .hidden-md .List-Guide--Bottom__Left {
  margin-bottom: 2rem;
}

.ant-carousel .slick-dots li button,
.ant-carousel .slick-dots li {
  height: 4px !important;
  width: 50px !important;
}

.category--slick span.slick-arrow {
  display: block;
  background: #545cb4;
  width: 32px;
  height: 32px;
}

.category--slick span.slick-arrow.slick-disabled {
  background: #545cb461;
}

.category--slick span.slick-arrow.slick-disabled:hover {
  background: #545cb461;
}

.category--slick span.slick-arrow:hover {
  background: #fb4242;
}

.category--slick span.slick-arrow svg {
  color: #fff;
  font-size: 26px;
  padding-top: 6px;
}

.title-group {
  padding: 0 0.5rem;
  word-break: break-word;
}

.title-group.inactive {
  font-size: 1.07rem;
  color: #3c3086;
  font-family: "Raleway-Medium";
  cursor: pointer;
}

.title-group.active,
.title-group:hover {
  font-size: 1.07rem;
  color: #fb4242;
  font-family: "Raleway-Bold";
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  margin-top: -25px !important;
}

.category--slick {
  border-bottom: 1px solid #dae4f5;
  padding-bottom: 15px;
}

.EcardBlock {
  margin-bottom: 2rem;
}

.footer--top {
  background: #0b0d17;
}

.footer--top a {
  color: #eeeff4;
  font-size: 0.93rem;
  font-family: "Raleway-Medium";
  display: flex;
  margin-bottom: 0.8rem;
}

.footer--top p {
  color: #eeeff4;
  font-size: 0.93rem;
  font-family: "Raleway-Medium";
  display: flex;
}

.w-full {
  width: 100%;
}

.footer--bottom {
  background: #04050b;
}

.footer--bottom p {
  color: #d9dbe1;
  font-size: 0.93rem;
  text-align: left;
  padding: 1.65rem 0;
  font-family: "Raleway-Medium";
}

.footer--top__container {
  padding: 4rem 0 2rem;
}

.m-b-8rem {
  margin-bottom: 0.8rem;
}

.m-b-5rem {
  margin-bottom: 5rem;
}

.m-w-90pc {
  max-width: 90%;
}

.text-right {
  text-align: right;
}

.p-speeddial-button.p-button.p-button-icon-only {
  border: 4px solid #ffcaca;
}

.p-speeddial-button .p-button-icon {
  font-weight: 700;
}

.ant-back-top {
  width: 48px !important;
  height: 48px !important;
}

.modal--showImg {
  width: auto !important;
}

.modal--showImg .ant-modal-footer {
  display: none !important;
}

.p-2rem {
  padding: 1.5rem;
}

.ecard-body {
  display: flex;
  height: 100%;
  flex-direction: column;
  border: 1px solid #cedcf2;
  padding: 1.5rem;
  border-radius: 10px;
}

.card--title {
  color: #5b5b5b;
  font-size: 1.067rem;
  font-family: Raleway-SemiBold;
  padding-top: 1.5rem;
  line-height: 1.067rem;
}

.category--slick__ecard .slick-list {
  padding-top: 3rem !important;
}

.category--slick__ecard .slick-dots {
  top: 2rem !important;
}

.ant-spin-dot-item {
  background-color: #fb4242;
}

.ant-spin-spinning {
  margin-top: 3rem;
}

.swiper-scrollbar {
  opacity: 1 !important;
  max-width: 15vw !important;
  height: 4px;
  background-color: #fec7c7;
  margin: 0 auto;
}

.swiper-scrollbar-drag {
  background: #fb4242;
  height: 4px;
}

.swiper-grid .swiper-wrapper {
  flex-wrap: wrap;
}

.mySwiper {
  margin-top: 2rem;
}
/* .swiper-grid-column > .swiper-wrapper {
  flex-wrap: wrap;
  flex-direction: column;
} */

.modal--showImg img {
  max-height: 75vh;
  /* max-width: 80vw; */
}

.mobile-menu .anticon-close {
  padding: 0.5rem;
  background-color: #f9f9f9;
}

.btn-receive-mobile {
  margin-top: 1rem;
}

.btn-receive-mobile__a {
  background: #5052ac;
  box-sizing: border-box;
  border-radius: 50px;
  padding: 1rem 2.5rem;
  font-size: 1.5rem;
  font-family: Raleway-Bold;
  color: #fff;
  margin-top: 1rem;
}

.btn-send-mobile__a {
  background: #ff5767;
  box-sizing: border-box;
  border-radius: 50px;
  padding: 1rem 2.5rem;
  font-size: 1.5rem;
  font-family: Raleway-Bold;
  color: #fff;
  margin-top: 1rem;
}

.break-word {
  word-break: break-word;
}

.card--title__left {
  text-align: left;
}

.card--title__right {
  text-align: right;
}

.border-solid-radius {
  right: -81px;
  top: calc(70% - 123px);
  position: fixed !important;
  height: 190px;
  width: 190px;
  border: 2px solid #b2b4ff;
  border-radius: 50%;
  z-index: 1;
}

.p-speeddial {
  right: 0px;
  top: calc(70% - 70px);
  position: fixed !important;
}

.p-speeddial-button.p-button.p-button-icon-only {
  right: 9px;
}

.border-dotLine-radius {
  right: -72px;
  top: calc(70% - 112px);
  position: fixed !important;
  height: 170px;
  width: 170px;
  border: 2px dashed #b2b4ff;
  border-radius: 50%;
}

@media (max-width: 1199px) {
  .App-header {
    position: relative;
    top: 0;
  }

  .mobile-menu {
    display: block;
  }

  .nav-options {
    display: flex;
    width: 100%;
    height: 350px;
    position: absolute;
    top: 63px;
    left: -200%;
    opacity: 0;
    transition: all 0.5s ease;
    flex-direction: column;
    list-style-type: none;
    grid-gap: 0px;
  }
  .nav-options.active {
    background: linear-gradient(180deg, #282da2 0%, #2e1a52 100%);
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 9999;
    align-content: center;
    padding-left: 0px;
    width: 200%;
    height: 100vh;
    padding: 8rem 0;
  }

  .nav-options {
    /* padding-left: 25px;
  display: grid; */
    grid-template-columns: repeat(3, auto);
    grid-gap: 50px;
    list-style-type: none;
  }
  .option {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 10vw;
    padding: 30px 0px;
    flex-direction: column;
  }
}
.nav-options a {
  font-family: "Raleway-Bold";
  font-size: 1.2rem;
  color: #fff !important;
  font-weight: 700;
  padding: 2rem;
}

.nav-options a:hover {
  color: #f97b9c;
}

.CenteredPage--Top__Left {
  background: url("../src/public/img/common/centerd-page-left.png") no-repeat;
  background-size: cover;
  background-position: top right;
}

.CenteredPage--Bottom__Right {
  background: url("../src/public/img/common/centerd-page-right.png") no-repeat;
  background-size: cover;
  background-position: top left;
}
.m-t-3rem {
  margin-top: 3rem;
}

.m-t-6rem {
  margin-top: 6rem;
}

.m-b-2rem {
  margin-bottom: 2rem;
}

.exp-block .slick-track {
  display: flex !important;
}

.exp-block .slick-track .slick-slide {
  height: inherit !important;
  display: flex !important;
}

.exp-block .slick-track .slick-slide > div,
.exp-block .slick-track .slick-slide .product-item {
  height: inherit !important;
  display: flex !important;
}

.swiper {
  position: initial !important;
}

.swiperParent {
  position: relative;
}

.swiper-button-next {
  right: -35px !important;
  color: #545cb4 !important;
}

.swiper-button-prev {
  left: -35px !important;
  color: #545cb4 !important;
}

.ant-modal-confirm-info .ant-modal-confirm-btns,
.ant-modal-confirm-info .anticon-info-circle {
  display: none;
}

.ant-modal-confirm-info .ant-modal-confirm-content {
  text-align: center;
}

.ant-modal-confirm .ant-modal-body {
  padding: 15px 15px 15px !important;
}

.ant-modal-confirm-info .ant-modal-confirm-content {
  margin-left: 0 !important;
}
.popup-qr-parent {
  display: flex;
}

.popup-qr:first-child {
  display: flex;
  flex-grow: 1;
}

.popup-qr:last-child {
  display: flex;
  flex-grow: 4;
}

.popup-qr p {
  text-align: left;
  font-family: 'Raleway-Medium';
  font-size: 15px;
}

.modal-qr .ant-modal-close-x {
  width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
}

.modal-qr .ant-modal-content {
  border-radius: 25px;
}