
.speeddial-circle-demo .p-speeddial-semi-circle.p-speeddial-direction-left {
    right: 48px;
    top: calc(50% - 2rem);
}

.p-speeddial {
    right: 48px;
    top: calc(70% - 70px);
    position: fixed !important;
}
 
.p-speeddial-button {
    transform: scaleX(-1);
}

.p-speeddial-button.p-button.p-button-icon-only {
    width: 48px;
    height: 48px;
    top: 15px;
}

.p-speeddial-button.p-button.p-button-icon-only .p-button-icon {
    font-size: 1rem;
}

.p-button.p-speeddial-button {
    color: #ffffff;
    background: #FB4242;
    border: 1px solid #FB4242;
}

.p-button.p-speeddial-button:enabled:active, .p-buttonp-speeddial-button:not(button):not(a):not(.p-disabled):active {
    background: #f72e2e;
    color: #ffffff;
    border-color: #FFCACA;
}

.p-button.p-speeddial-button:enabled:hover, .p-button.p-speeddial-button:not(button):not(a):not(.p-disabled):hover {
    background: #f72e2e;
    color: #ffffff;
    border-color: #FFCACA;
}

.p-button:focus {
    outline: 0 none;
    outline-offset: 0;
    /* box-shadow: 0 0 0 0.2rem #FFCACA; */
    box-shadow: none;
}